import React, { useEffect, useState } from 'react'
import './restaurant.css'
import '../../admin.css'
import Slider from 'react-slick'
import {
    Breadcrumb, Affix, Row, Col, Avatar, Card, Typography, Tag, Modal, Result, Skeleton,
    Button, Divider, Badge, Drawer, Alert, Input, Image, InputNumber, Spin, Space, App,
    Collapse,
    Select,
    Layout,
    Flex
} from 'antd';
import {
    HomeOutlined, StarFilled, InfoCircleFilled, QuestionCircleOutlined, LeftOutlined,
    InfoCircleOutlined, ClockCircleOutlined
} from '@ant-design/icons';
import { Footer } from '../../component/footer'
import { api, MP_PUBLIC_KEY } from '../../service/api.service'
import AppBar from '../../component/appBar'
import Bairros from '../../component/bairros'
import Informacoes from '../../component/informacoes'
import Avaliacoes from '../../component/avaliacoes'
import Produto from '../../component/produto'
import SacolaProdutos from '../../component/sacolaProdutos'
import UserComponent from '../../component/userComponent';
import AddressComponent from '../../component/addressComponent';
import FidelidadeComponent from '../../component/fidelidadeComponent';
import CuponsComponent from '../../component/cuponsComponent';
import Orders from '../../component/orders';
import TotaisComponent from '../../component/totaisComponent';
import { moneyMask, primeiraLetraMaiuscula, renderButtonWhatshap } from '../../service/dal.service';
import { getTokenFirebase } from '../../service/firebaseService'
import shoppingbag from '../../img/shopping-bag.png'
import { isDesktop, isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
    rec_lgpd, rec_dataLGPD, pedidoState, selCurrentAddress,
    lojaState, userState, TIME_SEGUNDOS
} from '../../store/dataRecoil'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useToasts } from 'react-toast-notifications';
import * as moment from 'dayjs'
import { Payment, initMercadoPago } from '@mercadopago/sdk-react';
import PIXICON from '../../img/pix.jpg'
import TRANSACTION_PNG from '../../img/transaction.png'
import { valorMonetarioOnChange } from '../../service/util'
import AuthenticationService from '../../service/authenticationService'
import { auth } from '../../service/firebaseService';
import PAY_ENTREGA from '../../img/pay-na-entrega.png'
import PAY_ONLINE from '../../img/pay-online.png'

const { Paragraph } = Typography;
const { TextArea } = Input;

export const Restaurant = (props) => {

    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const address = useRecoilValue(selCurrentAddress);
    const toash = useToasts()
    const [lgpd, saveLGPD] = useRecoilState(rec_lgpd);
    const [dataLGPD, saveDataLGPD] = useRecoilState(rec_dataLGPD)
    const [pedido, setPedido] = useRecoilState(pedidoState)
    const [loja, setLoja] = useRecoilState(lojaState)
    //  const user = useRecoilValue(userState)
    const [user, saveUser] = useRecoilState(userState)
    const [formasPagamento, setFormasPagamento] = useState([])
    const [loading, setLoading] = useState(true)
    const [isModalBairrosVisible, setIsModalBairrosVisible] = useState(false)
    const [isModalInfoVisible, setIsModalInfoVisible] = useState(false)
    const [data, setData] = useState(undefined)
    const [modalAvaliacoes, setModalAvaliacoes] = useState(false)
    const [subtotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [modalProduto, setModalProduto] = useState(false)
    const [itemSelected, setItemSelected] = useState({})
    const [disableConfirmar, setDisableConfirmar] = useState(false)
    const [modalSacola, setModalSacola] = useState(false)
    const [obs, setObs] = useState('')
    const [fpSelected, setFpSelected] = useState(null)
    const [value, setValue] = useState("".concat(data?.loja?.pagonline))
    const [modalTroco, setModalTroco] = useState(false)
    const [modoTroco, setModoTroco] = useState(0)
    const [changeFor, setChangeFor] = useState(0);
    const [processandoConfirmar, setProcessandoConfirmar] = useState(false)
    const [modalSuccess, setModalSuccess] = useState(false)
    const [openError, setOpenError] = useState(false)
    const [status_erro_detail, setStatus_erro_detail] = useState('')
    const [modalPedidosVisible, setModalPedidosVisible] = useState(false)
    const [loadingPayment, setLoadingPayment] = useState(true)
    const { message } = App.useApp();
    const [orderReturn, setOrderReturn] = useState(undefined)

    //const updateBricks = usePaymentBrick()





    async function loadOrder() {
        try {
            if (orderReturn) {
                if (orderReturn?.transactionMP?.status === "pending") {

                }
            }
        } catch (erro) {

        }
    }



    function checkUrlApp() {
        return location.pathname.substring(0, 5) === "/app/"
    }


    async function checkuser() {
        try {

            const userR = auth.currentUser
            if (!userR) {
                var ut = await (await auth.signInAnonymously()).user
                if (!user) {
                    saveUser({
                        uid: ut.uid,
                        isAnonymous: true
                    })
                } else if (!user?.uid) {
                    //  console.log("UID:", ut.uid, ut.isAno
                    saveUser({
                        uid: ut.uid,
                        isAnonymous: true
                    })
                }
            }

        } catch (error) {
            console.log('ERRO: ', error.message)
        }
    }



    async function onError(error) {
        // callback chamado para todos os casos de erro do Brick
        //   console.log('Erro de bricks', error.message);
        //  message.error(error.message)
        console.log(error.message)
    };
    async function onReady() {
        setLoadingPayment(false)
    };

    const settings = {
        dots: false,
        infinite: true,
        centerMode: true,
        speed: 1000,
        slidesToScroll: 1,
        slidesToShow: isDesktop ? 3 : 1,
        variableWidth: true,
        className: "slider variable-width sample-stick",
        style: {
            width: '100%'
        }
    }



    const DivStyle = styled.div`
    margin-top: 24px;
    background-size: 100%;
    max-height:  ${isMobile ? '90px' : (params.app ? '220px' : '280px')};
    height: ${isMobile ? '90px' : (params.app ? '220px' : '280px')};    
    margin-top:${params.app ? '0px' : '0px'};
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${data?.loja?.informacoes?.Url}); 
    display: flex;
    flex-direction: column;  
    justify-content: space-between; 
`;



    //Pagamento online
    function renderPaymentOnline() {

        return <div>
            {(loja && loadingPayment === true) && <Skeleton active />}
            <Payment
                initialization={initial}
                customization={customization}
                key={user?.uid}
                onSubmit={onSubmit}
                onReady={onReady}
                onError={onError}
            />


        </div>


    }


    const initial = {
        amount: total
    };
    const customization = {
        paymentMethods: {
            bankTransfer: loja?.pixEnable === 1 ? "all" : undefined,
            creditCard: "all",
            mercadoPago: "all",
            minInstallments: 1,
            maxInstallments: 1,
        },
        visual: {
            hideFormTitle: true,
            defaultPaymentOption: {
            },
            //   hidePaymentButton: true
        }
    }

    async function onSubmit({ selectedPaymentMethod, formData }) {

        let validarOnline = true
        if (selectedPaymentMethod === 'bank_transfer' && formData.payment_method_id === 'pix') {
            if (!formData.payer.email)
                validarOnline = false
        }

        if (checkDisableConfirmar()) {
            if (pedido?.type === "DELIVERY" && address.valor < 0)
                message.warning('Parece que o seu endereço não é atendido pelo estabelecimento!')
            else
                message.warning('Verifique se você informou todos os campos obrigtários!')
            validarOnline = false
        }

        if (!validarOnline) {
            message.warning('Verifique se você informou todos os campos obrigtários!')
            window?.paymentBrickController?.unmount()
            setValue(undefined)
            return
        }

        //  console.log(getStrutureOrder(formData.payment_method_id), formData, selectedPaymentMethod)
        var ped = {
            pedido: await getStrutureOrder(selectedPaymentMethod),
            payment: formData
        }
        try {
            //  console.log(ped)
            // return

            const response = await api.post('/api/v2.0/pedidos/neworder/bricks', ped);
            try {
                window?.paymentBrickController?.unmount()
                // setValue(undefined)
            } catch (error) { }
            if (response.status === 200) {
                // console.log("RESPOSTA: ", response.data)
                setOrderReturn(response.data)
                if (response.data) {
                    setProcessandoConfirmar(false)
                    if (response.data.key && !response.data.error) {
                        clearPedido()
                        setModalSacola(false)
                        setModalSuccess(true)
                    } else {
                        setStatus_erro_detail(response.data.error_detail)
                        setOpenError(true);
                        setProcessandoConfirmar(false)
                    }

                } else {
                    setStatus_erro_detail('Não foi possível efetuar o pedido! Erro no processamento!');
                    setOpenError(true);
                    setProcessandoConfirmar(false)
                }
                //setFpSelected(undefined)
            } else {
                setStatus_erro_detail('Não foi possível efetuar o pedido! Servidor retornou status inválido!!');
                setOpenError(true);
            }

        } catch (erro) {
            console.log("Erro retorno", erro.message)
            setStatus_erro_detail(erro.message);
            setOpenError(true);
            setProcessandoConfirmar(false)
        }
        setProcessandoConfirmar(false)

    };






    useEffect(() => {
        checkuser()
        const interval = setInterval(() => {
            if (orderReturn && modalSuccess) {
                loadPedido(orderReturn?.pedid, orderReturn?.path)
            }
        }, 15000)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        const intervalStatus = setInterval(() => {
            refreshStatus()
        }, 60000)
        return () => clearInterval(intervalStatus)
    }, [])


    useEffect(() => {
        // saveLGPD(true)
        // console.log("location: ", location.pathname.substring(0, 5))
        moment.locale('pt-br');
        setProcessandoConfirmar(false)
        if (!dataLGPD) {
            saveLGPD(true)
            saveDataLGPD(moment().add(-1, 'days').format('YYYY-MM-DD'))
        }
        else if (moment(dataLGPD).isBefore(moment().format('YYYY-MM-DD'))) {
            saveLGPD(true)
        }


        if (checkUrlApp()) {
            if (pedido.path !== params.restaurant) {
                clearPedido()
            }
            load()
        } else {
            if (params.uf.length !== 2) {
                if (!checkUrlApp())
                    navigate('/notfound')
            } else {
                if (pedido.path !== params.restaurant) {
                    clearPedido()
                }
                load()
            }
        }
        getTokenFirebase()
        refreshPedido()
        setValue("".concat(data?.loja?.pagonline))

    }, [])


    useEffect(() => {
        if (data) {
            if (checkUrlApp()) {

            } else {
                if (params.uf.toLowerCase() !== data?.loja?.informacoes?.Estado.toLowerCase()) {
                    if (!checkUrlApp())
                        navigate('/notfound')
                }
            }

            try {
                let link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                link.href = data?.menuengfood?.url;
                document.title = data?.loja?.informacoes?.Fantasia
            } catch (erro) {

            }

        }
    }, [data])


    useEffect(() => {
        calcularTotais()
        checkDisableConfirmar()
    }, [address, pedido, user, subtotal, total, value, fpSelected, changeFor])




    async function loadPedido(pedid, path) {
        try {

            if (modalSuccess) {
                const response2 = await api.get('api/v2.0/pedido2?uid='.concat(user.uid, '&pedid=', pedid, '&path=', path));
                if (modalSuccess)
                    setOrderReturn(response2.data)
                else
                    setOrderReturn(undefined)
            }

        } catch (erro) {
            //  console.log('pedidos screen erro', erro)

        }
    }



    function clearPedido() {
        setPedido({
            createdAt: new Date().getTime(),
            local: params.restauran,
            itens: [],
            type: pedido.type,
            fidelidade: undefined,
            cupom: pedido.cupom
        })
    }


    function showOrders(value) {
        setModalPedidosVisible(value)
    }



    async function addItem(item) {
        // console.log(item)
        if (data?.open === true) {
            setModalProduto(true);
            var item2 = JSON.parse(JSON.stringify(item))
            if (!item2.Venda)
                item2.Venda = 0;

            if (item2.ispattern === 0)
                item2.Venda = parseFloat(item2.Venda)

            if (!item2.Quant)
                item2.Quant = 1;
            if (item2.AtivarPromocao) {
                if (item2.AtivarPromocao === 1 && item2.ValorPromocao > 0) {
                    if (!item2.Venda2)
                        item2.Venda2 = item2.Venda
                    item2.Venda = item2.ValorPromocao;
                }
            } else {
                item2.Venda2 = item2.Venda
            }
            setItemSelected(item2);
        }
        else {
            toash.addToast('O estabelecimento está fechado, não é possível adicionar produtos!',
                { appearance: 'warning', autoDismiss: true })

        }
    }



    async function refreshStatus() {
        try {
            const response = await api.get('/api/v2.0/restaurantStatus', {
                params: {
                    uf: params.uf,
                    cidade: params.cidade,
                    path: params.restaurant
                }
            });

            //  let aux = {}//JSON.parse(JSON.stringify(data))
            //  aux.open = response.data.open
            if (data) {
                setData({ ...data, open: response.data.open })
            }
            console.log("refreshStatus", response.data.open)

        } catch (error) {
            console.log(error.message)
        }
    }



    async function load() {
        try {
            setLoading(true)
            const response = await api.get('/api/v2.0/restaurante', {
                params: {
                    uf: params.uf,
                    cidade: params.cidade,
                    path: params.restaurant
                }
            });

            setData(response.data)
            setLoja(response.data.loja)
            setFormasPagamento(response.data.loja.formas_pagamento)

            if (response.data.loja.mpkey) {
                initMercadoPago(MP_PUBLIC_KEY, { locale: 'pt-BR' })
            }

        } catch (error) {
            toash.addToast(error.message, { appearance: 'error', autoDismiss: true })
            if (!checkUrlApp())
                navigate('/notfound')
        }
        setLoading(false)
    }


    function renderPreco(item) {

        var isPromocao = item?.AtivarPromocao === 1 ? true : false; //Se é promoção
        if (item.from) {
            return <span style={{ fontSize: '1em', color: '#4cc58d', marginTop: 5, marginLeft: 5 }}>{item.from}</span>
        } else {
            if (item.Venda && item.Venda > 0) {
                return <span>
                    {isPromocao && <span style={{ marginTop: 5, marginLeft: 5, marginRight: 10, color: '#4cc58d', fontSize: '1em' }}>
                        <span style={{ fontSize: '1em', color: '#4cc58d' }}>
                            {"Por R$".concat(item.ValorPromocao.toFixed(2))} </span>
                    </span>}
                    <span style={Object.assign({
                        fontSize: isPromocao ? '0.7em' : '1em', color: isPromocao ? 'gray' : '#4cc58d', marginTop: 5, marginLeft: 5
                    }, isPromocao ? { textDecorationLine: "line-through" } : {})}>
                        {
                            item.Venda && (item.Venda > 0 && ''.concat(isPromocao ? 'De ' : '', 'R$', item.Venda.toFixed(2).toString()))
                        }
                    </span>

                </span>

            } else {
                var from = item.Venda;
                if (item.grupos) {
                    item.grupos.map(grupo => {
                        if (grupo.obrigatorio === 1) {

                        }
                    })
                    item.from = item.from ? 'a partir de R$'.concat(from.toFixed(2)) : ''
                    //  return item
                }
                return <span style={{ fontSize: '1.0em', color: '#4cc58d', marginTop: 5, marginLeft: 5 }}>{item.from}</span>
            }
        }
    }


    return isMobile ? renderMobile() : renderDeskTop()




    function renderInfoHeaderMobile() {

        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: 10
        }}>
            {

                <div style={{
                    display: 'flex', flexDirection: 'row', width: '100%',
                    justifyContent: 'space-between'
                }}>
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                        width: '100%'
                    }}>
                        <Avatar size={60} shape="square"
                            src={data?.menuengfood?.url} style={{ marginTop: 0, marginLeft: 10 }} />
                        <div style={{
                            display: 'flex', flexDirection: 'column',
                            flexFlow: 1, flex: 1
                        }}>
                            <div style={{
                                fontSize: 'large', marginTop: -2,
                                color: loja?.cor, marginLeft: 5
                            }}>
                                {data?.loja?.informacoes?.Fantasia}
                            </div>
                            <div style={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center',
                                justifyContent: 'space-between',
                                flex: 1
                            }}>
                                <Space direction='horizontal' size={'small'}
                                    style={{ alignItems: 'center', cursor: 'pointer' }}>

                                    <Tag style={{ width: 80, textAlign: 'center', marginLeft: 5 }}
                                        color={data?.open ? '#87d068' : '#f50'}>
                                        <ClockCircleOutlined />  {data?.open ? 'aberto' : 'fechado'}
                                    </Tag>


                                    {data?.loja?.informacoes?.whatsappenable === 1 &&
                                        renderButtonWhatshap("".concat(data?.loja?.informacoes?.whatsappcode,
                                            data?.loja?.informacoes?.whatsappnumber), undefined, undefined, user?.displayName)
                                    }
                                    <InfoCircleOutlined style={{ fontSize: 20 }}
                                        onClick={() => setIsModalInfoVisible(true)} />

                                </Space>

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Tag color="#FFD700" onClick={() => setModalAvaliacoes(true)}>
                                        <StarFilled style={{ marginRight: 3 }} />
                                        <b style={{ fontSize: 16 }}>  {data?.loja?.rate} </b>
                                    </Tag>
                                    {
                                        (data?.loja?.rate !== '' && data?.loja?.rate !== 'nova') &&
                                        <Tag color={'white'} onClick={() => setModalAvaliacoes(true)}>
                                            <span style={{ color: '#000', fontSize: '0.75em' }}> {data?.loja?.avaliacoes} Avaliações </span>
                                        </Tag>

                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            }


            {
                /*
                data?.loja && <div style={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                    {
                        loja?.informacoes?.taxtype === 0 && <Button size='small' color="primary"
                            onClick={() => setIsModalBairrosVisible(true)}>
                            Bairros
                        </Button>
                    }                       
                </div>
                */
            }

        </div>
    }

    function renderSlider() {
        return data?.menu.length > 3 ? <Slider {...settings} style={{
            marginLeft: 30,
            marginRight: 30,
            minHeight: 'auto'
        }}>
            {
                data?.menu && data?.menu?.map((cat, key) => {
                    return <div key={"K-".concat(key)} style={{ display: 'flex', minHeight: 0, minWidth: 0 }}>
                        <Button
                            href={"#cat-".concat(cat.idcategorias)}
                            style={{ margin: isDesktop ? 20 : 'auto' }}>
                            <Space direction='horizontal'>
                                <Typography.Text style={{ fontSize: '0.8em' }}>{cat.catDescricao}</Typography.Text>
                            </Space>
                        </Button>
                    </div>
                })
            }
        </Slider> : <div />
    }


    function renderMobile() {

        return <Layout style={{
            height: '100vh',
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column'
        }}>
            <Layout.Header style={{
                position: 'sticky',
                top: 0,
                cursor: 'pointer',
                paddingLeft: 0,
                paddingRight: 0,
                marginTop: -2,
                backgroundColor: 'transparent',
                zIndex: 999
            }}>
                {
                    loading ? <div style={{ width: '100%' }}>
                        <Skeleton.Input size='large' active block />
                        <Skeleton.Input size='large' active block style={{ marginTop: 10 }} />
                        <Skeleton.Input size='small' active block style={{ marginTop: 10 }} />
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 10 }}>
                            <Skeleton.Input size='small' active />
                            <Skeleton.Input size='small' active />
                            <Skeleton.Input size='small' active />
                        </div>
                        <Skeleton.Input size='small' active block style={{ marginTop: 10 }} />
                    </div> : <AppBar history={props.history} block />

                }
            </Layout.Header>

            <Layout.Content
                style={{
                    display: 'flex',
                    flex: 1,
                    marginTop: -2,
                    flexDirection: 'column',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}>
                <DivStyle>
                    {
                        (!params?.app && data?.loja) && <div style={{
                            marginTop: 8, marginLeft: 10, marginRight: 10, display: 'flex',
                            flexDirection: 'row', justifyContent: 'space-between', height: 90
                        }}>
                            <Flex align='flex-end' style={{ marginBottom: 2 }}>
                                <Tag color="#4c4ce4"  >
                                    <Breadcrumb style={{ color: 'white' }}>
                                        <Breadcrumb.Item href={!checkUrlApp() ? "/cidades" : '#'} style={{ color: 'red' }}>
                                            <HomeOutlined style={{ color: 'white', fontSize: 20 }} />
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item style={{ color: 'white' }}>{data?.loja?.informacoes?.Estado}</Breadcrumb.Item>
                                        <Breadcrumb.Item style={{ color: 'white' }}>
                                            <span style={{ color: 'white' }}>{data?.loja?.informacoes?.Cidade}
                                            </span>

                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Tag>
                            </Flex>
                        </div>
                    }
                </DivStyle>
                {data?.loja && renderInfoHeaderMobile()}
                <div className="div-content-rest-cardapio"
                    style={{ backgroundColor: 'whitesmoke', marginTop: 10 }}>
                    {
                        loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {loading && <Spin tip='carregando' />}
                        </div>
                    }

                    {
                        renderSlider()
                    }

                    {
                        data?.menu && data?.menu?.map((cat, key) => {
                            return <Row key={"km".concat(key)}>
                                <Col span={24} className="col-category">
                                    <div>
                                        <div
                                            id={"cat-".concat(cat.idcategorias)}
                                            style={{
                                                marginTop: 10, padding: 10, marginBottom: 0,
                                                fontSize: isMobile ? "0.8em" : '1.0em',
                                                fontFamily: 'Poppins,sans-serif',
                                                color: '#515050'
                                            }}>
                                            {primeiraLetraMaiuscula(cat.catDescricao)}
                                        </div>
                                        <Row gutter={[16, 0]}>
                                            {
                                                cat.data.map((prod, key2) => {
                                                    return <Col className="col-produto" xs={24} lg={12} xl={8} key={"kk".concat(key2)}>
                                                        <Card style={{ cursor: 'pointer' }}
                                                            onClick={() => addItem(prod)}>
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                padding: 15
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'space-between',
                                                                    justifyContent: 'space-between'
                                                                }}>
                                                                    <div>
                                                                        <div style={{
                                                                            fontSize: '1em',
                                                                            color: '#515050',
                                                                            fontWeight: 700,
                                                                            textAlign: 'left'
                                                                        }}>
                                                                            {primeiraLetraMaiuscula(prod.NomeReduzido)}
                                                                        </div>
                                                                        <div style={{ marginTop: 10, textAlign: 'left' }}>
                                                                            <Paragraph className="paragraph-produto-line"
                                                                                ellipsis={{ rows: 3, expandable: false, symbol: '...' }}>
                                                                                {prod.Info}
                                                                            </Paragraph>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0, fontSize: '1.1em', textAlign: 'left' }}>
                                                                        {renderPreco(JSON.parse(JSON.stringify(prod)))}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {
                                                                        prod.Url && <img
                                                                            style={{
                                                                                height: 68, width: 70,
                                                                                borderRadius: 12,
                                                                                backgroundColor: 'whitesmoke'
                                                                            }}
                                                                            src={prod.Url} />
                                                                    }

                                                                </div>
                                                            </div>

                                                        </Card>

                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        })
                    }
                </div>
                {<Footer />}
                {renderOthers()}



            </Layout.Content>



        </Layout>




        return <div style={{ backgroundColor: 'white' }}>

            {

                loading ? <div style={{ width: '100%' }}>
                    <Skeleton.Input size='large' active block />
                    <Skeleton.Input size='large' active block style={{ marginTop: 10 }} />
                    <Skeleton.Input size='small' active block style={{ marginTop: 10 }} />
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 10 }}>
                        <Skeleton.Input size='small' active />
                        <Skeleton.Input size='small' active />
                        <Skeleton.Input size='small' active />
                    </div>
                    <Skeleton.Input size='small' active block style={{ marginTop: 10 }} />
                </div> : <AppBar history={props.history} block />

            }



            <DivStyle>
                {
                    (!params?.app && data?.loja) && <div style={{
                        marginTop: 10, marginLeft: 10, marginRight: 10, display: 'flex',
                        flexDirection: 'row', justifyContent: 'space-between'
                    }}>
                        <Tag color="#4c4ce4" >
                            <Breadcrumb style={{ color: 'white' }}>
                                <Breadcrumb.Item href={!checkUrlApp() ? "/cidades" : '#'} style={{ color: 'red' }}>
                                    <HomeOutlined style={{ color: 'white', fontSize: 20 }} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item style={{ color: 'white' }}>{data?.loja?.informacoes?.Estado}</Breadcrumb.Item>
                                <Breadcrumb.Item style={{ color: 'white' }}>{data?.loja?.informacoes?.Cidade}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Tag>


                    </div>
                }
            </DivStyle>

            {data?.loja && renderInfoHeaderMobile()}




            <div className="div-content-rest-cardapio"
                style={{ backgroundColor: 'whitesmoke', marginTop: 10 }}>
                {
                    loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {loading && <Spin tip='carregando' />}
                    </div>
                }


                {
                    renderSlider()
                }



                {

                    data?.menu && data?.menu?.map((cat, key) => {
                        return <Row key={"km".concat(key)}>
                            <Col span={24} className="col-category">
                                <div>
                                    <div
                                        id={"cat-".concat(cat.idcategorias)}
                                        style={{
                                            marginTop: 10, padding: 10, marginBottom: 0,
                                            fontSize: isMobile ? "1.5em" : '1.3em',
                                            fontFamily: 'Poppins,sans-serif', color: '#515050'
                                        }}>
                                        {primeiraLetraMaiuscula(cat.catDescricao)}
                                    </div>
                                    <Row gutter={[16, 0]}>
                                        {
                                            cat.data.map((prod, key2) => {
                                                return <Col className="col-produto" xs={24} lg={12} xl={8} key={"kk".concat(key2)}>
                                                    <Card style={{ cursor: 'pointer' }}
                                                        onClick={() => addItem(prod)}>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            padding: 15
                                                        }}>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'space-between',
                                                                justifyContent: 'space-between'
                                                            }}>
                                                                <div>
                                                                    <div style={{
                                                                        fontSize: '1.2em',
                                                                        color: '#515050',
                                                                        fontWeight: 700
                                                                    }}>
                                                                        {primeiraLetraMaiuscula(prod.NomeReduzido)}
                                                                    </div>
                                                                    <div style={{ marginTop: 10 }}>
                                                                        <Paragraph className="paragraph-produto-line"
                                                                            ellipsis={{ rows: 3, expandable: false, symbol: '...' }}>
                                                                            {prod.Info}
                                                                        </Paragraph>
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginTop: 0, fontSize: '1.5em' }}>
                                                                    {renderPreco(JSON.parse(JSON.stringify(prod)))}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {
                                                                    prod.Url && <img
                                                                        style={{
                                                                            height: 88, width: 90,
                                                                            borderRadius: 12,
                                                                            backgroundColor: 'whitesmoke'
                                                                        }}
                                                                        src={prod.Url} />
                                                                }

                                                            </div>
                                                        </div>

                                                    </Card>

                                                </Col>
                                            })
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    })
                }
            </div>
            {<Footer />}
            {renderOthers()}

        </div>
    }

    function renderDeskTop() {
        return <div className="default-main">
            <AppBar history={props.history} />
            <div className={isMobile ? 'default-content-mobile' : 'default-content'}>

                <DivStyle>
                    {!params?.app && <div style={{ marginTop: -10, marginLeft: 10 }}>
                        <Tag color="#4c4ce4" >
                            <Breadcrumb style={{ color: 'white' }} items={[
                                {
                                    title: <a href={!checkUrlApp() ? "/cidades" : '#'} style={{ color: 'white' }}>
                                        <HomeOutlined style={{ color: 'white', fontSize: 20 }} />
                                    </a>
                                },
                                {
                                    title: data?.loja?.informacoes?.Estado
                                },
                                {
                                    title: <span style={{ color: 'white' }}> {data?.loja?.informacoes?.Cidade} </span>
                                }
                            ]}>
                            </Breadcrumb>
                        </Tag>
                    </div>
                    }
                    <div style={{ float: 'left', marginBottom: -45 }}>
                        <div className="div-avatar-rest" >
                            <div className="div-avatar-rest-itens">
                                <Avatar size={90} shape="square"
                                    src={data?.menuengfood?.url} />
                                {
                                    data?.loja && <Tag style={{ marginTop: 5, width: 70, textAlign: 'center', marginLeft: 10 }}
                                        color={data?.open ? '#87d068' : '#f50'}>
                                        {data?.open ? 'aberto' : 'fechado'}
                                    </Tag>
                                }
                            </div>
                        </div>
                        <div className="div-avaliacao-rest" >
                            <Tag color="#FFD700" onClick={() => setModalAvaliacoes(true)}>
                                <StarFilled style={{ marginRight: 3 }} />
                                <b style={{ fontSize: 16 }}>  {data?.loja?.rate} </b>
                            </Tag>

                            {
                                (data?.loja?.rate !== '' && data?.loja?.rate !== 'nova') &&
                                <Tag color={"white"} onClick={() => setModalAvaliacoes(true)}>
                                    <span style={{ color: '#000' }}>  {data?.loja?.avaliacoes} Avaliações </span>
                                </Tag>

                            }

                            {data?.loja?.informacoes?.whatsappenable === 1 &&
                                renderButtonWhatshap("".concat(data?.loja?.informacoes?.whatsappcode,
                                    data?.loja?.informacoes?.whatsappnumber), undefined, undefined, user?.displayName)
                            }
                        </div>
                    </div>

                </DivStyle>

                <div style={{ padding: 0, backgroundColor: 'whitesmoke' }}>
                    <div style={{ padding: 20 }}>
                        <div className="content-restaurants">
                            <div className={"div-name-rest"} style={{ fontSize: isMobile ? 'large' : 'x-large' }}>
                                {data?.loja?.informacoes?.Fantasia}
                            </div>
                        </div>

                        {data?.loja && <div className="row-info-rest">
                            {
                                loja?.informacoes?.taxtype === 0 && <div className="row-info-rest-itens" style={{ cursor: 'pointer' }}
                                    onClick={() => setIsModalBairrosVisible(true)}>
                                    <div className="row-info-rest-item-header">ver bairros</div>
                                    <div>entrega</div>
                                </div>
                            }

                            <div className="row-info-rest-itens">
                                <div className="row-info-rest-item-header">{data?.loja?.informacoes?.tempo1}</div>
                                <div>minutos</div>
                            </div>
                            <div className="row-info-rest-itens">
                                <div className="row-info-rest-item-header">R$ {data?.loja?.informacoes?.minimo.toFixed(2)}</div>
                                <div>minímo</div>
                            </div>
                            <div className="row-info-rest-itens" style={{ cursor: 'pointer' }}
                                onClick={() => setIsModalInfoVisible(true)}>
                                <div className="row-info-rest-item-header">
                                    <InfoCircleFilled style={{ fontSize: 20, color: '#c2c2c2' }} />
                                </div>
                                <div>informações</div>
                            </div>
                            {
                                /*
                                    <div className="row-info-rest-itens" style={{ cursor: 'pointer' }}>
                                {data?.loja?.informacoes?.whatsappenable === 1 &&
                                    renderButtonWhatshap("".concat(data?.loja?.informacoes?.whatsappcode,
                                        data?.loja?.informacoes?.whatsappnumber), undefined, undefined, user?.displayName)
                                }
                            </div>
                                */
                            }

                        </div>
                        }

                        <div className="div-content-rest-cardapio">
                            {
                                loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {loading && <Spin tip='carregando' />}
                                </div>
                            }

                            {
                                renderSlider()
                            }

                            {
                                data?.menu && data?.menu?.map((cat, key) => {
                                    return <Row key={"ke".concat(key)}>
                                        <Col span={24} className="col-category">
                                            <div>
                                                <div
                                                    id={"cat-".concat(cat.idcategorias)}
                                                    style={{
                                                        marginTop: 35, padding: 10, marginBottom: 20,
                                                        fontSize: isMobile ? "1.3em" : '1.2em'
                                                    }}>
                                                    {primeiraLetraMaiuscula(cat.catDescricao)}
                                                </div>
                                                <Row gutter={[16, 16]}>
                                                    {
                                                        cat.data.map((prod, key2) => {
                                                            return <Col key={'p'.concat(key2)}
                                                                className="col-produto" xs={24} lg={12} xl={8} >
                                                                <Card size='small' style={{ height: 180, cursor: 'pointer' }}
                                                                    onClick={() => addItem(prod)}>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',


                                                                    }}>
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'space-between',
                                                                            justifyContent: 'space-between',
                                                                            height: 150,
                                                                        }}>
                                                                            <div>
                                                                                <div style={{
                                                                                    fontSize: '1.2em',
                                                                                    color: '#515050',
                                                                                    fontWeight: 700
                                                                                }}>
                                                                                    {primeiraLetraMaiuscula(prod.NomeReduzido)}
                                                                                </div>
                                                                                <div style={{ marginTop: 10 }}>
                                                                                    <Paragraph className="paragraph-produto-line"
                                                                                        ellipsis={{ rows: 3, expandable: false, symbol: '...' }}>
                                                                                        {prod.Info}
                                                                                    </Paragraph>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ marginTop: 15, fontSize: '1.5em' }}>
                                                                                {renderPreco(JSON.parse(JSON.stringify(prod)))}
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                prod.Url && <img
                                                                                    style={{
                                                                                        height: 88,
                                                                                        width: 90,
                                                                                        borderRadius: 12,
                                                                                        backgroundColor: 'whitesmoke'
                                                                                    }}
                                                                                    src={prod.Url} />
                                                                            }

                                                                        </div>
                                                                    </div>

                                                                </Card>

                                                            </Col>
                                                        })
                                                    }
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

            {
                renderOthers()
            }




        </div>
    }


    function renderTroco() {
        try {
            const troco = parseFloat(changeFor) - parseFloat(total)
            if (troco < 0)
                return <span style={{ color: 'red', width: 160 }}>
                    O valor mínimo deve ser R$<br /> {total.toFixed(2)} (valor total)
                </span>
            else
                return <span style={{ color: 'gray' }}>Troco R$ {(parseFloat(changeFor) - parseFloat(total)).toFixed(2)}</span>
        } catch (error) {
            return <div>.</div>
        }
    }



    function renderEntrega() {
        return <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ color: 'gray' }}>Indique sua preferência:</div>
                <div><Select size='small'
                    placement={'topLeft'}
                    style={{ width: 170 }}
                    value={fpSelected?.fpid}
                    onChange={(e) => {
                        var item = [...formasPagamento].find(value => value.fpid === e)
                        setFpSelected(item)
                        setChangeFor(0)
                        if (item) {
                            var selected = {
                                name: item.bandeira,
                                code: item.bandeira.substring(0, 3),
                                value: 0,
                                prepaid: 0,
                                issuer: item.bandeira,
                                Url: item.Url,
                                tipo: item.bandeira.toUpperCase() === 'DINHEIRO' ? item.bandeira : 'Pagamento na Entrega',
                                info: item.bandeira.toUpperCase() === 'DINHEIRO' ? ' ' : item.bandeira,
                                changeFor: 0
                            }
                            setPedido({ ...pedido, payments: [selected] })
                            if (item.bandeira.toUpperCase() === 'DINHEIRO') {
                                setModoTroco(0)
                                setModalTroco(true)
                            }
                        } else {
                            setPedido({ ...pedido, payments: [] })
                        }
                    }}>
                    {
                        formasPagamento.map((fp, index) => {
                            return <Select.Option
                                value={fp.fpid}
                                key={"fp-select-".concat(index)}>
                                {fp.bandeira}
                            </Select.Option>
                        })
                    }
                </Select> </div>
            </div>
            {
                fpSelected?.bandeira.toUpperCase() === "DINHEIRO" &&
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 8
                }}>
                    <div style={{ color: 'gray' }}>Com quanto você vai pagar?</div>
                    <div>
                        <div>
                            <Input
                                size='small'
                                value={changeFor}
                                onChange={(e) => {
                                    var value = valorMonetarioOnChange(e)
                                    setChangeFor(value)
                                }}
                                style={{ width: 170, textAlign: 'right' }}
                            />
                            <div style={{ fontSize: '0.8em', color: 'gray', textAlign: 'right' }}>
                                {renderTroco()}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div>
                <Button block type="primary" style={{ marginTop: 15 }}
                    loading={processandoConfirmar}
                    disabled={disableConfirmar}
                    onClick={() => {
                        confirmarPedido()
                    }}>
                    Confirmar Pedido
                </Button>
            </div>
        </div >
    }




    function renderOthers() {
        return <div>
            <Modal title={null} open={isModalBairrosVisible}
                footer={null}
                closable={false}
                onOk={() => setIsModalBairrosVisible(false)}
                onCancel={() => setIsModalBairrosVisible(false)}>
                <Bairros bairros={data?.loja?.bairros} />
                <Divider style={{ marginTop: 0, marginBottom: 10 }} />
                <div className="div-modal-bairros-footer">
                    <Button type="primary" onClick={() => setIsModalBairrosVisible(false)}>Fechar</Button>
                </div>
            </Modal>


            <Drawer
                zIndex={1000}
                headerStyle={{ backgroundColor: 'white' }}
                width={isMobile ? '100%' : 500}
                title={"Informações"}
                open={isModalInfoVisible}
                onClose={() => setIsModalInfoVisible(false)}
            >
                <div style={{ width: '100%' }}>
                    <Informacoes loja={data?.loja} open={data?.open} />
                </div>
            </Drawer>


            {/* AVALIAÇÕES */}
            <Modal title={null} open={modalAvaliacoes}
                footer={null}
                onOk={() => setModalAvaliacoes(false)}
                onCancel={() => setModalAvaliacoes(false)}>
                <Avaliacoes loja={data?.loja} />
            </Modal>



            {
                <Drawer
                    zIndex={1000}
                    headerStyle={{ backgroundColor: 'white' }}
                    title={
                        <Space direction='vertical' size={'small'} style={{ width: '100%' }}>
                            <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Space direction='horizontal'>
                                    <Avatar shape='square' size={'large'} src={itemSelected?.Url} />
                                    <strong>
                                        {itemSelected?.NomeReduzido}
                                    </strong>
                                </Space>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: itemSelected?.AtivarPromocao === 1 ? 'column' : 'row'
                                }}>

                                    <span style={Object.assign({
                                        fontSize: itemSelected.AtivarPromocao === 1 ? 13 : 15,
                                        color: itemSelected.AtivarPromocao === 1 ? 'gray' : 'green',
                                        marginTop: 5,
                                        marginLeft: 5
                                    }, itemSelected.AtivarPromocao === 1 ? { textDecorationLine: "line-through" } : {})}>

                                        {
                                            itemSelected?.ispattern === 0 ? '' :
                                                itemSelected?.Venda2 && (itemSelected.Venda2 > 0 &&
                                                    ''.concat(itemSelected.AtivarPromocao === 1 ? 'De ' : '', 'R$', itemSelected?.Venda2.toFixed(2).toString()))
                                        }
                                    </span>

                                    {itemSelected?.AtivarPromocao === 1 && <Tag color={"magenta"}
                                        style={{
                                            justifyContent: 'flex-end',

                                        }}>
                                        {"Por R$".concat(itemSelected.ValorPromocao.toFixed(2))} </Tag>}

                                </div>


                            </div>
                        </Space>
                    } placement="right"
                    destroyOnClose={true}
                    width={isMobile ? '100%' : 500}
                    onClose={() => setModalProduto(false)}
                    open={modalProduto}
                >
                    <Produto produto={itemSelected} shoModaProduto={shoModaProduto} />
                </Drawer>
            }



            {/* MEUS PEDIDOS*/}
            <Drawer title={'Meus Pedidos'}
                zIndex={1000}
                headerStyle={{ backgroundColor: 'white' }}
                placement="right"
                width={isMobile ? '100%' : 500}
                onClose={() => {
                    setModalPedidosVisible(false)
                }}
                open={modalPedidosVisible}
                destroyOnClose={true}>
                <Orders path={params.restaurant} showOrders={showOrders} />
            </Drawer>

            {
                (pedido?.itens?.length > 0 && loading === false) && <Affix offsetBottom={0} >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button block
                            icon={<Badge count={pedido?.itens?.length}> <img src={shoppingbag} height={24} />
                            </Badge>} type="primary" size={'large'}
                            onClick={() => setModalSacola(true)}>
                            <span style={{ marginLeft: 10 }}>  ver sacola</span>
                        </Button>
                    </div>
                </Affix>
            }


            <Drawer
                zIndex={1000}
                destroyOnClose={true}
                title="SEU PEDIDO"
                footer={null}
                placement={'right'}
                width={isMobile ? '100%' : 500}
                closable={true}
                closeIcon={<LeftOutlined />}
                onClose={() => {
                    setChangeFor(0)
                    setModalSacola(false)
                    setOrderReturn(undefined)

                    setFpSelected(null)
                }}

                afterOpenChange={() => {
                    setFpSelected(null)
                }}

                bodyStyle={{ backgroundColor: 'white' }}
                headerStyle={{ backgroundColor: 'white' }}
                open={modalSacola}
                key={'right'}>
                <Row>
                    <Col span={24}>{renderAlertMinimo()}</Col>
                    <Col span={24}>{renderAlertCupom()}</Col>
                    <Col span={24}>{<UserComponent path={params.restaurant} />}</Col>
                    <Col span={24}>{<AddressComponent path={params.restaurant} />} </Col>
                    <Col span={24}><SacolaProdutos path={params.restaurant} /> </Col>
                    <Col span={24}><FidelidadeComponent path={params.restaurant} /> </Col>
                    <Col span={24}><CuponsComponent path={params.restaurant} /> </Col>
                    <Col span={24}><TotaisComponent path={params.restaurant} loja={data?.loja} /> </Col>
                    <Col span={24}></Col>
                    <Col span={24}>
                        <TextArea rows={3} maxLength={100} value={obs}
                            onChange={(e) => setObs(e.target.value)} style={{ marginBottom: 5 }}
                            placeholder="Suas observações aqui" />
                    </Col>
                    <Col span={24} style={{ marginBottom: 7, marginTop: 10, color: 'royalblue' }}>
                        <b >Como deseja pagar?</b>
                    </Col>
                    <Col span={24}>
                        {
                            renderPagamento()
                        }
                    </Col>

                    <Col span={24}>{renderAlertMinimo()}</Col>
                    <Col span={24}>{renderAlertCupom()}</Col>
                    <Col span={24}>
                        {
                            status_erro_detail && <Alert
                                message={<strong>Falha ao enviar pedido!</strong>}
                                description={status_erro_detail}
                                type="error"

                            />
                        }
                    </Col>
                    <Col span={24}>
                        {
                            /* value !== '1' && value !==1 && <Button block type="primary" style={{ marginTop: 15 }}
                                loading={processandoConfirmar}
                                disabled={disableConfirmar}
                                onClick={() => {
                                    confirmarPedido()
                                }}>
                                Confirmar Pedido
                            </Button>
                            */
                        }
                    </Col>

                </Row>


            </Drawer>


            {
                renderModalError()
            }

            {
                renderModalSuccess()
            }

            <Drawer
                zIndex={1000}
                title={null}
                placement={'bottom'}
                width={100}
                height={'auto'}
                closable={false}
                onClose={() => { }}
                open={lgpd}>
                <div>
                    <div>
                        Estamos em conformidade com a Lei Geral de Proteção de Dados(LGPD) e utilizamos cookies para
                        melhorar sua experiência de navegação. Ao clicar em "Aceitar" você concede com a utilização de
                        cookies.
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 10, marginBottom: 20 }}>
                        <Button type='primary'
                            onClick={() => {
                                saveLGPD(false)
                                saveDataLGPD(moment().add(2, 'days').format('YYYY-MM-DD'))
                            }}>Aceitar
                        </Button>
                    </div>

                </div>
            </Drawer>
        </div>
    }


    function renderPagamento() {
        return <Collapse size='small' accordion defaultActiveKey={value} onChange={(v) => {
            console.log(v)
            setValue(v[0])
        }} style={{ marginBottom: 5 }}>
            <Collapse.Panel header={<span>
                <img src={PAY_ENTREGA} style={{ marginRight: 5 }} height={18} alt='na entrega' />
                Na entrega
            </span>} key={"0"}>
                {renderEntrega()}
            </Collapse.Panel>
            <Collapse.Panel header={<span>
                <img src={PAY_ONLINE} style={{ marginRight: 5 }} height={18} alt='na entrega' />
                Online</span>} key={"1"}>
                {renderOnline()}
            </Collapse.Panel>
        </Collapse>

    }




    function renderOnline() {
        return data?.loja?.pagonline === 1 ? <Row gutter={[16, 8]}  >
            <Col span={24}>
                {
                    renderPaymentOnline()
                }
            </Col>
        </Row> :
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Alert
                        message={<b>Pagamentos ONLINE</b>}
                        description="Essa loja não possui pagamentos ONLINE, apenas na entrega do pedido."
                        type="info"
                        showIcon
                        closable={false}
                    />
                    <br />
                </Col>
            </Row>
    }




    function renderModalTroco() {
        switch (modoTroco) {
            case 1:
                return <div>
                    <div style={{ textAlign: 'center' }}>
                        <b>Troco para Quanto?</b>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Seu pedido deu <b>R$ {total.toFixed(2)}</b><br />
                        Informe quanto vai pagar em dinheiro para que o entregador leve o seu troco.
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <InputNumber
                            step='0.01'
                            decimalSeparator={','}
                            min={0}
                            stringMode={true}
                            value={moneyMask(changeFor)}
                            onChange={(e) => {
                                setChangeFor(e)
                            }}
                        />
                    </div>
                    <div className="modal-content-troco">
                        <div>  <Button type="primary" onClick={() =>
                            confirmarTroco()}>Confirmar</Button></div>
                    </div>
                </div>


            default:
                return <div>
                    <div style={{ textAlign: 'center' }}>
                        <QuestionCircleOutlined style={{ color: 'royalblue', marginRight: 10, fontSize: 25 }} />
                        <b>Você vai precisar de troco?</b>
                    </div>
                    <div className="modal-content-troco">
                        <div> <Button type="default" danger onClick={() => noTroco()}>Não</Button></div>
                        <div>  <Button type="primary"
                            onClick={() => {
                                setModoTroco(1)
                            }}>Sim</Button></div>
                    </div>
                </div>
        }
    }


    function confirmarTroco() {
        console.log('changeFor', changeFor)
        //  var changeFor = parseFloat(changeFor)
        //setShow(false)
        if (changeFor > total) {
            setModalTroco(false)
            var selected = fpSelected;
            selected.changeFor = changeFor
            selected.info = "Troco para R$".concat(changeFor)
            selected.code = "Din"
            selected.issuer = selected.bandeira
            selected.prepaid = 0
            pedido.payments = [
                selected
            ]
            setFpSelected(selected)

        } else {
            toash.addToast("Você deve pedir troco para um valor maior que R$".concat(total.toFixed(2)),
                { appearance: 'warning', autoDismiss: true })

        }
    }


    function noTroco() {
        try {
            setModalTroco(false)
            var selected = fpSelected //Object.assign({}, fpSelected);
            selected.changeFor = 0
            selected.info = "Sem troco"
            selected.code = "Din"
            selected.issuer = selected.bandeira
            selected.prepaid = 0
            pedido.payments = [
                selected
            ]
            setFpSelected(selected)
            //  console.log("pedido.payments", pedido.payments)
        } catch (erro) { }
    }


    function clearFp() {
        var fps = JSON.parse(JSON.stringify(formasPagamento))
        fps.map(fp => {
            fp.selected = false
        })
        setFormasPagamento(fps)
    }


    function clickFp(item) {
        //  console.log('click', item)
        var fps = JSON.parse(JSON.stringify(formasPagamento))
        var sel = null
        fps.map(fp => {
            fp.selected = false
            if (item) {
                if (fp.fpid === item.fpid) {
                    fp.selected = true
                    sel = fp;
                }
            }
        })

        setFormasPagamento(fps)


        if (item)
            setFpSelected(sel)
        else
            setFpSelected(undefined)

        if (item) {
            var selected = {
                name: item.bandeira,
                code: item.bandeira.substring(0, 3),
                value: 0,
                prepaid: 0,
                issuer: item.bandeira,
                Url: item.Url,
                tipo: item.bandeira.toUpperCase() === 'DINHEIRO' ? item.bandeira : 'Pagamento na Entrega',
                info: item.bandeira.toUpperCase() === 'DINHEIRO' ? ' ' : item.bandeira,
                changeFor: 0
            }

            setPedido({ ...pedido, payments: [selected] })

            if (item.bandeira.toUpperCase() === 'DINHEIRO') {

                var selected3 = fpSelected //Object.assign({}, fpSelected);
                if (selected3) {
                    selected3.changeFor = 0
                    selected3.info = ""
                }
                setModoTroco(0)
                setModalTroco(true)
                // setShow(true)
            }
        } else {
            setPedido({ ...pedido, payments: [] })
        }
    }


    function shoModaProduto(value) {
        setModalProduto(value)
    }

    function renderAlertMinimo() {
        var subtotal = 0
        pedido?.itens?.map((item, index) => {
            subtotal = (item.valorUnitarioFinal * item.Quant) + subtotal
        })
        if (data?.loja?.informacoes?.minimo > subtotal && subtotal > 0) {

            return <Alert
                message={null}
                description={'O pedido mínimo para essa loja é de R$'
                    .concat(data?.loja?.informacoes?.minimo.toFixed(2), ' não inclusa a taxa de entrega.')}
                type="warning"
                showIcon
            />
        } else
            return <div></div>
    }

    function renderAlertCupom() {
        var subtotal = 0
        if (pedido?.cupom) {
            pedido.itens.map((item, index) => {
                subtotal = (item.valorUnitarioFinal * item.Quant) + subtotal
            })
            if (pedido?.cupom.minimo > subtotal) {
                return <Alert
                    style={{ marginTop: 5 }}
                    message={null}
                    description={'O pedido mínimo para utilizar o cupom é R$ '
                        .concat(pedido.cupom.minimo.toFixed(2), ' não inclusa a taxa de entrega.')}
                    type="warning"
                    showIcon
                />
            } else
                return <div></div>
        } else {
            return <div></div>
        }
    }



    function checkDisableConfirmar() {
        var disable = true;
        try {
            // console.log('address.taxa', address.taxa)          
            if (pedido?.type === "DELIVERY") {
                if (address) {
                    if (address.taxa >= 0) {
                        if (pedido.itens.length > 0)
                            if (user) {
                                // if (user?.phoneNumber)
                                if (user?.displayName)
                                    if (user?.phoneNumber) {
                                        disable = false
                                    }
                            }
                    }
                }
            } else {
                if (pedido?.itens.length > 0)
                    if (user)
                        if (user.displayName)
                            disable = false;
            }

            if (disable === true) {
                setDisableConfirmar(disable)
                return disable
            }

            if (subtotal < data?.loja?.informacoes?.minimo)
                disable = true

            if (pedido?.cupom)
                if (subtotal < pedido?.cupom?.minimo)
                    disable = true

            if (total <= 0)
                disable = true

            if (parseInt(value) === 1) {

            } else {

                if (pedido.payments) {
                    if (pedido.payments.length <= 0)
                        disable = true;
                } else {
                    disable = true
                }

                if (!fpSelected)
                    disable = true

                if (fpSelected?.bandeira.toUpperCase() === 'DINHEIRO') {
                    const troco = parseFloat(changeFor) - parseFloat(total)
                    console.log("trco: ", troco)
                    if (troco < 0)
                        disable = true
                }

            }
            //  console.log(disable)
            setDisableConfirmar(disable)

        } catch (error) {
            setDisableConfirmar(true)
        }
        return disable
    }

    function calcularTotais() {
        var subtotal = 0;
        pedido.itens.map((item, index) => {
            subtotal = (item.valorUnitarioFinal * item.Quant) + subtotal
            return
        })
        var total = 0;
        var cupom = 0;
        var fidelidade = 0

        if (pedido?.cupom !== undefined) {
            cupom = -1 * pedido?.cupom.valor;
            // setCupom(cupom)
        }

        if (pedido?.fidelidade !== undefined) {
            fidelidade = -1 * pedido?.fidelidade.valor;
            //  setFidadelidade(fidelidade)
        }

        if (address !== undefined) {
            if (address.taxa > 0 && pedido.type === "DELIVERY")
                total = subtotal + address.taxa + cupom + fidelidade
            else
                total = subtotal + cupom + fidelidade;
        } else {
            total = subtotal + cupom + fidelidade;
        }
        setTotal(total)
        setSubTotal(subtotal)
    }



    async function confirmarPedido() {
        setStatus_erro_detail(undefined)
        setProcessandoConfirmar(true)
        if (pedido.cupom) {
            if (subtotal < pedido.cupom.minimo) {

                toash.addToast("Não foi possível validar o seu cupom de desconto pois o valor mínimo de "
                    .concat(pedido.cupom.minimo, " não foi atingido."), {
                    appearance: 'warning',
                    autoDismiss: true
                })
                setStatus_erro_detail("Não foi possível validar o seu cupom de desconto pois o valor mínimo de "
                    .concat(pedido.cupom.minimo, " não foi atingido."))
                setProcessandoConfirmar(false);
                return;
            }
        }

        if (total <= 0) {
            toash.addToast("Não foi possível validar o seu pedido, o valor do pedido deve ser maior que R$0,00",
                { appearance: 'warning', autoDismiss: true })
            setStatus_erro_detail("Não foi possível validar o seu pedido, o valor do pedido deve ser maior que R$0,00")
            setProcessandoConfirmar(false)
            return;
        }

        if (parseInt(value) === 1) {
            var usuario = user
            if (usuario) {
                if (!usuario.email) {
                    toash.addToast("Para pagamento online é obrigatório informar o e-mail!",
                        { appearance: 'warning', autoDismiss: true })
                    setStatus_erro_detail("Para pagamento online é obrigatório informar o e-mail!")
                    setProcessandoConfirmar(false);
                    return;
                }
            } else {
                toash.addToast("Dados como nome e telefone são obrigatórios!",
                    { appearance: 'warning', autoDismiss: true })
                setStatus_erro_detail("Dados como nome e telefone são obrigatórios")
                setProcessandoConfirmar(false);
                return;
            }

            confirmarPedido2()

        } else {
            await confirmarPedido2()
        }
        setProcessandoConfirmar(false)
    }



    //Se já fizer muitos minutos do pedido ele é zerado
    function refreshPedido() {
        try {
            var carrinho = JSON.parse(JSON.stringify(pedido))
            var value = new Date().getTime();
            if (!carrinho) {
                carrinho = {
                    createdAt: value,
                    local: carrinho.path,
                    itens: [],
                    type: 'DELIVERY'
                };
            }
            if (value - carrinho.createdAt > TIME_SEGUNDOS) {
                // console.log('AQUI')
                carrinho = {
                    createdAt: value,
                    local: carrinho.path,
                    itens: [],
                    type: 'DELIVERY'
                }
                carrinho = {
                    createdAt: carrinho.createdAt,
                    local: carrinho.path,
                    itens: carrinho.itens,
                    type: carrinho.type,
                    fidelidade: carrinho.fidelidade,
                    cupom: carrinho.cupom
                }
                setPedido(carrinho)
            }
        } catch (error) {

        }
    }



    async function getStrutureOrder(selectedPaymentMethod) {
        try {

            var usuario = JSON.parse(JSON.stringify(user))

            if (!usuario.uid) {
                console.log('usuario: ', undefined)
            }


            usuario.nome = usuario.displayName;
            usuario.telefone = usuario.phoneNumber;
            var token = ''
            usuario.token = token
            var order = JSON.parse(JSON.stringify(pedido));

            var pays = order.payments;
            if (parseInt(value) === 1) { //PAGAMENTO ONLINE               
                var pay = {
                    name: selectedPaymentMethod ? selectedPaymentMethod : 'pagonline',
                    code: 'ONL',
                    changeFor: 0,
                    info: selectedPaymentMethod ? selectedPaymentMethod : 'online',
                    issuer: selectedPaymentMethod ? selectedPaymentMethod : 'online',
                    tipo: 'Pagamento no app',
                    value: total.toFixed(2),
                    externalCode: 'ONL',
                    prepaid: true,
                    prepaid2: true,
                    //  token: cardtoken.id,
                    Url: '',
                    //  cpf: CPF.replace('.', '').replace('-', ''),
                    totaltime: new Date().getTime(),
                    description: data?.loja?.informacoes?.Fantasia.concat(' - Delivery')
                }
                order.payments = [pay]
                order.status = selectedPaymentMethod === 'pix' ? 9 : 0
            } else {
                // var pays = order.payments;
                if (pays.length > 0) {
                    pays[0].value = total;
                    pays[0].name = (pays[0].name === null || pays[0].name === undefined) ? "Dinheiro" : pays[0].name
                    pays[0].code = (pays[0].code === null || pays[0].code === undefined) ? "DIN" : pays[0].code
                    pays[0].externalCode = (pays[0].externalCode === null || pays[0].externalCode === undefined) ? "DIN" : pays[0].externalCode
                    pays[0].issuer = (pays[0].issuer === null || pays[0].issuer === undefined) ? "Dinheiro" : pays[0].issuer
                }
                order.status = 0
            }

            order.datadispositivo = new Date().getTime()
            order.datalocal = new Date()
            order.platform = 'web'//Platform.OS;
            order.datalong = new Date().getTime()
            order.distancia = 0
            order.taxa = pedido.type === "DELIVERY" ? address.taxa : 0
            order.dataserver = new Date()
            order.key = ""
            order.valor = total.toFixed(2)
            order.motivocancelamento = ''
            order.obs = obs.toString()
            //  console.log(fpSelected?.bandeira, parseFloat(changeFor), parseFloat(order.valor))
            if (parseFloat(changeFor) > parseFloat(order.valor) && fpSelected?.bandeira.toUpperCase() === "DINHEIRO") {
                order.obs = obs.toString()
                    .concat(" Troco para R$ ",
                        order.valor,
                        " (",
                        (parseFloat(changeFor) - parseFloat(order.valor)).toFixed(2),
                        ")")
            }
            order.uid = user.uid
            order.engpointscalculados = 0
            order.possuiengpoints = 0
            order.origem = 1
            order.novopedido = 0
            order.datahora = new Date()
            order.sendnotification = 0
            order.saiuentrega = 0
            order.latitude = 0
            order.longitude = 0
            order.path = pedido.local;
            order.endereco = address;// this.params.path // this.state.address
            order.usuario = usuario;
            order.path = params.restaurant
            order.produtos = order.itens
            order.formaPagamento = order.payments[0] // fpSelected
            order.timestamp = new Date()//firebase.database.ServerValue.TIMESTAMP;

            return order;

        } catch (error) {
            return null
        }
    }



    async function confirmarPedido2() {




        setProcessandoConfirmar(true)
        // var order = getStrutureOrder()

        try {
            var ped = {
                pedido: await getStrutureOrder(undefined),
                payment: undefined
            }
            //  console.log(ped)
            //  return


            const response = await api.post('/api/v2.0/pedidos/neworder/bricks', ped);
            if (response.status === 200) {
                if (response.data) {
                    setProcessandoConfirmar(false)
                    //   console.log(response.data)
                    if (response.data.key !== '') {

                        setOrderReturn(response.data)
                        clearPedido()
                        setModalSacola(false)
                        setModalSuccess(true)

                    } else {
                        if (response.data.error === 'refused' || response.data.error === 'rejected') {
                            if (response.data.payments[0].status_detail)
                                setStatus_erro_detail(response.data.payments[0].status_detail)
                            else
                                setStatus_erro_detail('');
                            setOpenError(true);

                        } else {
                            setStatus_erro_detail('Não foi possível efetuar o pedido! Ocorreu um erro no processamento!');
                            setOpenError(true);
                        }

                        setProcessandoConfirmar(false)
                    }

                } else {

                    setStatus_erro_detail('Não foi possível efetuar o pedido! Erro no processamento!');
                    setOpenError(true);
                    setProcessandoConfirmar(false)
                }


            } else {
                console.log("Erro retorno !== 200")
                setStatus_erro_detail('Chave key não gerada!');
                setOpenError(true);
            }

        } catch (erro) {
            console.log("Erro retorno", erro.message)
            setStatus_erro_detail('Chave key não gerada!');
            setOpenError(true);
            setProcessandoConfirmar(false)
        }
        setProcessandoConfirmar(false)
        clearFp()
    }

    function renderModalSuccess() {
        return <Modal open={modalSuccess}
            footer={null}
            closable={false}
            afterClose={() => { setOrderReturn(undefined) }}
            destroyOnClose={true} >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Result
                        status="success"
                        title={"O seu pedido foi enviado ao estabelecimento."}
                        subTitle={<div> {orderReturn?.transactionMP?.status === "pending" ?
                            "Assim que efeuar pagamento seu pedido será processado!" :
                            "Assim que confirmarem o pedido lhe avisaremos."}

                        </div>}
                        extra={[
                            renderModalSuccessFooter()
                        ]}
                    >
                    </Result>
                </Col>
            </Row>

        </Modal>
    }


    function renderModalSuccessFooter() {
        //  moment.locale('pt-br')
        //console.log(orderReturn?.transactionMP.point_of_interaction?.transaction_data?.ticket_url)
        if (orderReturn) {
            if (orderReturn?.transactionMP?.status === "pending" &&
                orderReturn.transactionMP.point_of_interaction?.transaction_data?.ticket_url) {
                return <Space size={'small'} direction='vertical' style={{ width: '100%' }} key={"ref-1"}>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Alert showIcon message={<span style={{ fontWeight: 600 }}>O seu pagamento está pendente!</span>} type="warning" />

                    </div>
                    <Card >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={PIXICON} height={36} width={36} alt='pixicon' />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left', marginLeft: 20 }}>
                                <strong style={{ fontSize: '1.1em', textAlign: 'left' }}>R${parseFloat(orderReturn?.valor).toFixed(2)}</strong>
                                <span style={{ fontSize: '0.8em', color: 'gray' }}>
                                    Transação nº: {orderReturn?.transactionMP.id}
                                </span>
                                <div>
                                    <span style={{ fontSize: '0.8em', color: 'gray' }}>
                                        Você tem até às {moment(orderReturn?.transactionMP.date_created).add(30, "minute")
                                            .format('[às] HH:mm [h para efetuar o pagamento]')}
                                    </span>
                                </div>
                            </div>

                        </div>
                    </Card>
                    <Card>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={TRANSACTION_PNG} height={36} width={36} alt='pixicon' />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left', marginLeft: 20 }}>
                                <strong style={{ fontSize: '1.1em' }}>Pedido nº: {orderReturn?.key}</strong>
                                <span style={{ fontSize: '0.8em', color: 'gray' }}>
                                    {moment(orderReturn?.transactionMP.date_created).format('D [de] MMMM [de] YYYY, [às] HH:mm [h]')}
                                </span>
                            </div>
                        </div>
                    </Card>

                    <Space direction={isMobile ? 'vertical' : 'horizontal'} >
                        <Button type="default" key="console99" onClick={() => {
                            setModalSuccess(false)
                            setModalPedidosVisible(true)
                        }} >
                            Tudo certo, já paguei
                        </Button>

                        <Button type="link" style={{ backgroundColor: 'green', color: 'white' }} key="console"
                            href={orderReturn?.transactionMP.point_of_interaction?.transaction_data?.ticket_url} target='_blank'>
                            Efetuar pagamento
                        </Button>
                    </Space>

                </Space>
            } else {
                return <Button type="primary" key="console" onClick={() => {
                    setModalSuccess(false)
                    setModalPedidosVisible(true)
                }} >
                    Acompanhar Pedido
                </Button>
            }
        } else {
            return <Button type="primary" key="console2" onClick={() => {
                setModalSuccess(false)
                setModalPedidosVisible(true)
            }}>
                Acompanhar Pedido
            </Button>
        }
    }




    function renderModalError() {
        return <Modal open={openError}
            footer={null}
            closable={false}
            destroyOnClose={true} >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Result
                        status="error"
                        title="Hum, aconteceu uma coisa chata."
                        subTitle={<div> {status_erro_detail} <br />Não foi possível realizar o pedido.</div>}
                        extra={[
                            <Button type="primary" key="console" onClick={() => setOpenError(false)}>
                                Fechar
                            </Button>
                        ]}
                    >
                    </Result>
                </Col>
            </Row>

        </Modal>
    }


}


